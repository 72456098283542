<template>
  <ui-tabs
    class="-mt-5 -mx-6"
    main
    :tabs="tabs"
    :firstActiveTabRule="firstActiveTabRule"
    :activateTabCallback="activateTabCallback"
  />
</template>

<script>
import UiTabs from "@/components/ui/UiTabs.vue";
import { reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ROUTER_NAMES } from "@/router/router.enum";

export default {
  components: {
    UiTabs,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const tabs = reactive([
      {
        title: "Assessments",
        routeName: ROUTER_NAMES.SUPPLIER_NESTED_ASSESMENT,
      },
      {
        title: "Risk exposure",
        routeName: ROUTER_NAMES.SUPPLIER_NESTED_RISK_EXPOSURE,
      },
      {
        title: "Functions",
        routeName: ROUTER_NAMES.SUPPLIER_NESTED_FUNCTIONS,
      },
      {
        title: "Information",
        routeName: ROUTER_NAMES.SUPPLIER_NESTED_INFORMATION,
      },
      {
        title: "Contacts",
        routeName: ROUTER_NAMES.SUPPLIER_NESTED_CONTACTS,
      },
      {
        title: "Logs",
        routeName: ROUTER_NAMES.SUPPLIER_NESTED_LOGS,
      },
    ]);

    const firstActiveTabRule = function (tabs) {
      return tabs.find((tab) => route.name == tab.routeName) || tabs[0];
    };

    const activateTabCallback = function (from, to) {
      router.push({ name: to.routeName });
    };

    return {
      tabs,
      firstActiveTabRule,
      activateTabCallback,
    };
  },
};
</script>
